<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow width="600" height="180" :steps="workflowSteps" :currentStep="workflowIndex" :labelWidth="200"
        :lineWidth="140"></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="10">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="ACS > rôles d'un collaborateur" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading || running"></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters>
              <!-- Titre de la table -->
              <div>Consulter les rôles associés à un collaborateur</div>

              <v-spacer></v-spacer>

              <!-- Bouton de modification -->
              <v-btn v-if="canEditRole && collaboratorUuid && !modeEdition" icon color="primary" @click="clickOnModeEdition">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-row justify="end" no-gutters v-if="modeEdition">
                <v-btn class="btn" color="primary" text @click="clickOnCancelEdit" :disabled="loading">
                  Quitter l'édition
                </v-btn>

                <v-btn icon color="primary" :disabled="!hasChanged || loading" @click="save">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-card-title>

          <!-- Recherche de l'utilisateur -->
          <v-card-text class="pb-1">
            <v-autocomplete :items="users" v-model="user" :search-input.sync="search" no-data-text clearable
              @input="selected" :loading="loading || running"
              label="Saisir l'adresse mail du collaborateur"></v-autocomplete>
          </v-card-text>

          <!-- Table matrice des droits -->
          <v-card-text v-if="user" class="pt-1">
            <v-data-table :headers="headers" :items="availableIItems" item-key="name" :search="roleSearch"
              :custom-filter="filterOnlyCapsText" disable-pagination hide-default-footer sort-by="role">
              <!-- Template d'analyse des items en fonction des colonnes pour modifier l'affichage du contenu des cellules -->
              <template v-for="head in headers" v-slot:[`item.${head.value}`]="{ item }">
                <div :key="head.value">
                  <!-- Colonne "rôle" on affiche le role -->
                  <div v-if="head.value == 'role'">
                    {{ item[head.value] }}
                  </div>

                  <!-- Colonne color on affiche un logo rose -->
                  <div v-else-if="head.value == 'color'">
                    <div v-if="itemColor(item)">
                      <v-avatar color="primary" size="10" />
                    </div>

                    <div v-if="!itemColor(item)">
                      <v-avatar color="#CCCCCC" size="10" />
                    </div>
                  </div>

                  <!-- Pour les autres colonnes, on traite l'affichage -->
                  <div v-else>
                    <v-row no-gutters justify="center">
                      <v-icon v-if="viewIconCheck(item, head)" small class="mr-2" color="primary">
                        mdi-check
                      </v-icon>

                      <v-checkbox v-if="viewCheckBox(item, head)" off-icon="mdi-checkbox-blank-outline"
                        on-icon="mdi-checkbox-outline" v-model="item[head.value].hasRole" color="primary" hide-details
                        class="my-0" @click="onClickCheckBox(item['role'], head.value)"></v-checkbox>

                      <v-tooltip bottom v-if="item[head.value].isHerited">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" color="secondary" v-bind="attrs" v-on="on">
                            mdi-account-switch-outline
                          </v-icon>
                        </template>
                        <span>Héritage par rôle supérieur</span>
                      </v-tooltip>

                      <!-- Tooltip conteant l'icône d'héritage -->
                      <v-tooltip bottom v-if="viewIconInheritSF(item, head)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" color="secondary" v-bind="attrs" v-on="on">
                            mdi-account-supervisor
                          </v-icon>
                        </template>
                        <span>Héritage par service(s) / fonction(s)</span>
                      </v-tooltip>
                    </v-row>
                  </div>
                </div>
              </template>

              <!-- Template des éléments au-dessus de la datatable -->
              <template v-slot:top>
                <!-- Cadre pour pour les infos services et fonctions du collaborateur -->
                <v-card flat outlined class="mx-auto mb-4">
                  <v-row no-gutters class="ml-4 mt-4 mb-4">
                    <v-col cols="3">
                      <div>Service(s) du collaborateur :</div>
                    </v-col>
                    <v-col cols="9">
                      <v-row no-gutters>
                        <div v-for="service in userServices" :key="service.name">
                          <div class="mx-2">{{ service.name }}</div>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="ml-4 mt-4 mb-4">
                    <v-col cols="3">
                      <div>Fonction(s) du collaborateur :</div>
                    </v-col>
                    <v-col cols="9">
                      <v-row no-gutters>
                        <div v-for="func in userFunctions" :key="func.name">
                          <div class="mx-2">{{ func.name }}</div>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>

                <!-- option de choix de tous les rôles et champ de recherche des rôles -->
                <v-row class="mb-2">
                  <v-checkbox class="mx-2" on-icon="mdi-checkbox-outline" off-icon="mdi-checkbox-blank-outline"
                    v-model="viewAllRole" label="Afficher les rôles non associés" />

                  <v-text-field v-model="roleSearch" label="Rechercher un rôle" class="mx-4 ml-16"></v-text-field>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions v-if="modeEdition">
            <v-spacer></v-spacer>
            <v-btn outlined class="ma-2 px-4 btn" color="primary" :disabled="!hasChanged || loading" @click="save">
              <div class="capitalize">enregistrer</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <AlertNotSavedModifsComponent :show="showAlertQuit" @quit="onQuitAlert" @notquit="onNotQuitAlert" />

    <!-- afficher des messages -->
    <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout" :left="snackbarLeft"
      :right="snackbarRight" :top="snackbarTop" :bottom="snackbarBottom">{{ snackbarMessage }}</v-snackbar>
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import * as exceptions from "@/service/exception_to_message.js";

import AcsService from "@/service/acs/acs_service.js";
import AuthAdminService from "@/service/backend/auth_admin_service.js";
import { CollaboratorsService } from "@/service/user/collaborators_service.js";

import * as logger from "@/tools/logger.js";

import { hasRoles } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "UserRolesAcs",

  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /** le service de recherche de collaborateur. */
      serviceCollaborators:null,
      /**Le service de gestion des droits. */
      serviceAcs: null,
      serviceAuthAdminBackend: null,

      /**les utilisateurs retournés lors de la recherche incrémentale. */
      users: [],
      /** la date de la dernière recherche (pour éviter les résultats croisés)*/
      lastSearchTimestamp: Date.now(),

      /** le collaborateur sélectionné */
      user: null,
      /** L'id du collaborateur */
      collaboratorUuid: undefined,
      /** variable pour la saisie de recherche du collaborateur */
      search: "",

      /** variable pour la saisie de recherche du rôle */
      roleSearch: "",

      /** variable pour l'affichage de chargement de données */
      loading: false,
      running: false,

      /** Fait aparaître ou non les rôles qui ne sont pas affectés */
      viewAllRole: false,

      /** Tableau des headers de la vue table */
      headers: [
        { text: "Rôles", value: "role", width: "200px" },
        { text: "", value: "color", width: "50px", sortable: false },
        {
          text: "Service",
          value: "services",
          align: "center",
          sortable: false,
        },
        {
          text: "Fonction",
          value: "functions",
          align: "center",
          sortable: false,
        },
        {
          text: "Collaborateur",
          value: "user",
          align: "center",
          sortable: false,
        },
      ],

      /** Remplissage de la matrice */
      items: [],
      /** les items source de la table */
      itemsSource: [],

      /** Tableau des rôles héritage compris */
      rolesApm: [],

      /** Tableau contenant les rôles de chaques services */
      servicesRole: [],
      /** Tableau contenant les rôles de chaques fonction */
      functionRole: [],

      /** Objet contenant l'utilisateur apm */
      userApm: null,

      /** Contient les services et fonctions du collaborateur */
      userServices: [],
      userFunctions: [],

      /** Indique si l'utilisateur peut modifier les rôles */
      canEditRole: false,
      /** Indique que l'utilisateur est en mode d'édition des rôles */
      modeEdition: false,

      /** Définition du rôle nécessaire pour l'édition de la vue */
      roleForEdition: [RolesApplicationEnum.EditUserAcs],
    };
  },
  methods: {
    /** Chargement des données sans lien avec le collaborateur */
    async load() {
      try {
        logger.debug("Chargement des données.");
        this.loading = true;

        // Récupération de l'ensemble des services connus par acs
        this.servicesRole =
          await this.serviceAuthAdminBackend.getRolesForServices();
        // logger.debug("Les services acs  ", JSON.stringify(this.servicesRole));

        // Récupération de l'ensemble des fonctions connus par acs
        this.functionRole =
          await this.serviceAuthAdminBackend.getRolesForFonctions();
        // logger.debug("Les fonctions acs  ", this.functionRole[0]);

        // Récupération de l'ensemble des rôles acs
        this.rolesApm = await this.serviceAuthAdminBackend.getRoles();
        // logger.debug("Les rôles acs  ", this.rolesApm[0]);
      } catch (error) {
        logger.error("Errreur pendant le chargement des datas : ", error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Lance la recherche auprès de l'api et transforme la réponse en liste de collaborateur*/
    findUser(email) {
      logger.debug("FIND COLLAB  " + email);
      if (!email || email == "") {
        //le texte de recherche est vide, on reset
        this.user = null;
        this.users = [];
        return;
      }

      /** Obligation de saisie à 3 caractère */
      if (email.length < 3) {
        this.users = [];
        return;
      }

      let now = Date.now();
      this.lastSearchTimestamp = now;

      this.loading = true;

      //on lance la recherche par email
      this.serviceCollaborators
        .searchCollaboratorByEmail(email, now)
        .then((results) => {
          //on vérifie que l'on ne garde pas une réponse plus ancienne (à cause du traitement de la requête)
          if (results.ts < this.lastSearchTimestamp) {
            return;
          }

          let users = [];
          results.users.forEach((user) => {
            users.push({ text: user.email, value: user });
          });

          this.users = users;
        })
        .catch((err) => {
          console.error("problème lors de la recherche par email:" + err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**  */
    selected(value) {
      try {
        if (!value) {
          this.user = null;
          return;
        }
        // Récupère l'id du collaborateur
        this.collaboratorUuid = value.uuid;

        this.running = true;

        this.resetUser();

        // Récupération des infos de droits, des services et fonctions associées du collaborateur
        this.serviceAuthAdminBackend
          .getDetailForCollaborater(this.collaboratorUuid)
          .then(async (result) => {
            // Affectation de l'utilisateur
            this.userApm = result;
            logger.debug("result of get details ", result);

            // Extraction des services et fonctions du collaborateur
            this.userServices = this.servicesRole.filter((s) =>
              this.userApm.serviceIds.includes(s.id)
            );
            this.userFunctions = this.functionRole.filter((f) =>
              this.userApm.functionIds.includes(f.id)
            );

            // Construction de la matrice
            await this.loadMatrix();
          });
      } catch (error) {
        logger.error("Erreur chargement datas user :", error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.running = false;
      }
    },

    /** Réinitialise les données de la vue */
    resetUser() {
      this.userApm = null;
      this.userServices = [];
      this.userFunctions = [];
      this.items = [];
    },

    /** Chargement des datas de la matrice */
    async loadMatrix() {
      let rolesServices = [];
      for (let serv of this.userServices) {
        // On ajout les infos d'héritage sur chacuns des services
        serv.roles = this.serviceAcs.buildInheritance(
          this.rolesApm,
          serv.authorized
        );
        // Récupération du nom de chaque rôle
        let roles = serv.roles.map((role) => role.name);
        // Récupération des rôles qui ne sont pas déjà présents.
        let rol = roles.filter((r) => !rolesServices.includes(r));
        if (rol) {
          rolesServices.push(...rol);
        }
      }

      let rolesFunctions = [];
      for (let func of this.userFunctions) {
        // On ajout les infos d'héritage sur chacunes des fonctions
        func.roles = this.serviceAcs.buildInheritance(
          this.rolesApm,
          func.authorized
        );
        // Récupération du nom de chaque rôle
        let roles = func.roles.map((role) => role.name);
        // Récupération des rôles qui ne sont pas déjà présents.
        let rol = roles.filter((r) => !rolesFunctions.includes(r));
        if (rol) {
          rolesFunctions.push(...rol);
        }
      }

      // Récupération des rôles spécifiques à l'utilisateur avec héritage
      let rolesUser = this.serviceAcs.buildInheritance(
        this.rolesApm,
        this.userApm.rules.authorized
      );

      this.items = [];
      // Parcours des rôles et construction de la matrice
      for (let item of this.rolesApm) {
        // Modèle d'une row
        let row = {
          role: "",
          color: false,
          services: {
            hasRole: false,
          },
          functions: {
            hasRole: false,
          },
          user: {
            hasRole: false,
            isHerited: false,
          },
        };

        // Affectation de colonne role
        row.role = item.name;

        // Recherche de présence du rôle en cours dans les services
        let foundServ = rolesServices.find((r) => r == item.name);
        if (foundServ) {
          row.services.hasRole = true;
        }

        // Recherche de précsence du rôle en cours dans les fonctions
        let foundFunc = rolesFunctions.find((r) => r == item.name);
        if (foundFunc) {
          row.functions.hasRole = true;
        }

        // Recherche de présence du rôle en cours pour l'utilisateur
        let foundUser = rolesUser.find((r) => r.name == item.name);
        if (foundUser) {
          if (foundUser.herited) {
            row.user.isHerited = true;
          } else {
            row.user.hasRole = true;
          }
        }

        // Rôle trouvé dans un service, fonctions ou user --> color à vrai
        if (foundServ || foundFunc || foundUser) {
          row.color = true;
        }

        this.items.push(row);
      }

      // Tri des éléments de la matrice par ordre alphabétique du roles
      this.items = this.items.sort(function (a, b) {
        return a.role.localeCompare(b.role);
      });

      this.itemsSource = JSON.parse(JSON.stringify(this.items));
    },

    /** Sauvegarde des modifications */
    async save() {
      try {
        this.loading = true;
        logger.debug("SAVE");

        if (this.hasChanged) {
          let roles = this.items.filter((i) => i.user.hasRole);
          let userRoles = roles.map((r) => r.role);
          //logger.debug("roles utilisateur", userRoles);

          this.userApm.rules.authorized = userRoles;
          // this.userApm.customs.authorized = userRoles;
          // logger.debug("utilisateur", this.userApm);

          // Sauvegarde de l'utilisateur en base
          await this.serviceAuthAdminBackend.updateRolesForCollaborater(
            this.userApm,
            this.collaboratorUuid
          );

          // Parcours de la liste des items source pour mise à jour
          for (let itemSrc of this.itemsSource) {
            // Récupération de l'item identique à la source en cours
            let item = this.items.find((i) => i.role == itemSrc.role);

            // Recopie pour l'utilisateur la valeur affichée dans la source
            itemSrc.user.hasRole = item.user.hasRole;
          }
          this.loadMatrix();
          this.modeEdition = false;
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "sauvegarde des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    // Trouve tous les rôles qui ont chnagés et les retourne en un tableau
    findUserHasChanged() {
      let roleHasChanged = [];

      // Parcours les row de la matrice
      for (let item of this.items) {
        // Récupère la row source
        let itemSrc = this.itemsSource.find((i) => i.role == item.role);

        // Comparaison d'affectation du role
        if (itemSrc.user.hasRole != item.user.hasRole) {
          roleHasChanged.push(item.role);
        }
      }

      return roleHasChanged;
    },

    filterOnlyCapsText(value, role) {
      role = role.toUpperCase();

      return (
        value != null &&
        role != null &&
        typeof value === "string" &&
        value.toString().indexOf(role) !== -1
      );
    },

    /** Evènement sur le click du bouton crayon qui fait passer la vue en édition */
    clickOnModeEdition() {
      this.modeEdition = true;
    },

    // Permet de savoir si l'icone check doit être visible
    viewIconCheck(row, col) {
      // Vérification sur la colonne user
      if (col.value == "user" && this.modeEdition) {
        return false;
      } else {
        return row[col.value].hasRole;
      }
    },

    // Permet de savoir si la chackbox est visible
    viewCheckBox(row, col) {
      if (
        col.value == "user" &&
        this.modeEdition &&
        !row[col.value].isHerited &&
        !row.services.hasRole &&
        !row.functions.hasRole
      ) {
        return true;
      } else {
        return false;
      }
    },

    /** Permet de savoir si l'icône d'héritage de service ou fonction doit être affiché */
    viewIconInheritSF(row, col) {
      if (
        col.value == "user" &&
        (row.services.hasRole || row.functions.hasRole)
      ) {
        return true;
      } else {
        return false;
      }
    },

    /** Traite le click sur une checkbox
     * Reçois la ligne et la colonne
     * en fonction, attribu ou non l'héritage
     */
    onClickCheckBox(row, col) {
      // Récupère la ligne dans la liste d'items
      let item = this.items.find((i) => i.role == row);

      // Récupère l'état du check de la cellule
      let check = item[col].hasRole;

      // récupère le rôle dans la liste originale
      let roleApm = this.rolesApm.find((r) => r.name == row);

      // Parcours la liste des rôles hérités
      for (let role of roleApm.grant) {
        // Récupère la row héritée
        let it = this.items.find((i) => i.role == role);
        // La valeur d'héritage est mise à la même valeur que celle du parent
        it[col].isHerited = check;

        // Si le rôle hérité était déjà check, on met la valeur à faux.
        if (check) {
          it[col].hasRole = false;
        }
      }
    },

    /** Retourne vrai si l'utilisateur possède le rôle */
    itemColor(item) {
      if (
        item.services.hasRole ||
        item.functions.hasRole ||
        item.user.hasRole ||
        item.user.isHerited
      ) {
        return true;
      } else {
        return false;
      }
    },

    /** Annule le mode édition et remet les modifications dans l'état initial */
    clickOnCancelEdit() {
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function (value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.modeEdition = false;
            this.items = JSON.parse(JSON.stringify(this.itemsSource));
            this.loadMatrix();
          }
        };
      } else {
        this.modeEdition = false;
      }
    },
  },
  computed: {
    // Ne retourne que les rows à afficher
    availableIItems() {
      let items = [];

      if (this.viewAllRole) {
        return this.items;
      }

      // On ne récupère que les item qui ont color à true
      items = this.items.filter((i) => i.color);

      return items;
    },

    /** retourne vrai si un des items à chnagé par rapport à sa source */
    hasChanged() {
      let changed = false;

      if (!this.itemsSource) return false;

      let find = this.findUserHasChanged();
      if (find.length > 0) {
        changed = true;
      }

      return changed;
    },
  },
  watch: {
    /** Méthode de surveillance de saisie de recherche du collaborateur */
    search(value) {
      // Recherche incrémentale du collaborateur
      this.findUser(value);
    },
  },
  mounted() {
    // Configure les éléments si on peut éditer les rôles
    this.canEditRole = hasRoles(this.roleForEdition);

    //on affiche le bouton retour
    this.showBackButton = true;

    this.serviceCollaborators = new CollaboratorsService(this.$api.getCollaboratorsApi());
    this.serviceAcs = new AcsService(this.$api.getAcsApi());
    this.serviceAuthAdminBackend = new AuthAdminService(
      this.$api.getAuthAdminApi()
    );

    this.addStepForWorkflow("Choisir un périmètre");
    this.addStepForWorkflow("Consulter la configuration");
    this.nextStepForWorkflow();

    this.load();
  },
};
</script>
