var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: "ACS > rôles d'un collaborateur" },
                  }),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  active: _vm.loading || _vm.running,
                },
              }),
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-regular" },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("div", [
                            _vm._v(
                              "Consulter les rôles associés à un collaborateur"
                            ),
                          ]),
                          _c("v-spacer"),
                          _vm.canEditRole &&
                          _vm.collaboratorUuid &&
                          !_vm.modeEdition
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "primary" },
                                  on: { click: _vm.clickOnModeEdition },
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              )
                            : _vm._e(),
                          _vm.modeEdition
                            ? _c(
                                "v-row",
                                { attrs: { justify: "end", "no-gutters": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn",
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        disabled: _vm.loading,
                                      },
                                      on: { click: _vm.clickOnCancelEdit },
                                    },
                                    [_vm._v(" Quitter l'édition ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        color: "primary",
                                        disabled:
                                          !_vm.hasChanged || _vm.loading,
                                      },
                                      on: { click: _vm.save },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-content-save"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-1" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.users,
                          "search-input": _vm.search,
                          "no-data-text": "",
                          clearable: "",
                          loading: _vm.loading || _vm.running,
                          label: "Saisir l'adresse mail du collaborateur",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.search = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.search = $event
                          },
                          input: _vm.selected,
                        },
                        model: {
                          value: _vm.user,
                          callback: function ($$v) {
                            _vm.user = $$v
                          },
                          expression: "user",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.user
                    ? _c(
                        "v-card-text",
                        { staticClass: "pt-1" },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.availableIItems,
                              "item-key": "name",
                              search: _vm.roleSearch,
                              "custom-filter": _vm.filterOnlyCapsText,
                              "disable-pagination": "",
                              "hide-default-footer": "",
                              "sort-by": "role",
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.headers, function (head) {
                                  return {
                                    key: `item.${head.value}`,
                                    fn: function ({ item }) {
                                      return [
                                        _c("div", { key: head.value }, [
                                          head.value == "role"
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item[head.value]) +
                                                    " "
                                                ),
                                              ])
                                            : head.value == "color"
                                            ? _c("div", [
                                                _vm.itemColor(item)
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("v-avatar", {
                                                          attrs: {
                                                            color: "primary",
                                                            size: "10",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !_vm.itemColor(item)
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("v-avatar", {
                                                          attrs: {
                                                            color: "#CCCCCC",
                                                            size: "10",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                        justify: "center",
                                                      },
                                                    },
                                                    [
                                                      _vm.viewIconCheck(
                                                        item,
                                                        head
                                                      )
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-check "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.viewCheckBox(
                                                        item,
                                                        head
                                                      )
                                                        ? _c("v-checkbox", {
                                                            staticClass: "my-0",
                                                            attrs: {
                                                              "off-icon":
                                                                "mdi-checkbox-blank-outline",
                                                              "on-icon":
                                                                "mdi-checkbox-outline",
                                                              color: "primary",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onClickCheckBox(
                                                                  item["role"],
                                                                  head.value
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item[head.value]
                                                                  .hasRole,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item[
                                                                      head.value
                                                                    ],
                                                                    "hasRole",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item[head.value].hasRole",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      item[head.value].isHerited
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                      color:
                                                                                        "secondary",
                                                                                    },
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-account-switch-outline "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Héritage par rôle supérieur"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.viewIconInheritSF(
                                                        item,
                                                        head
                                                      )
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                      color:
                                                                                        "secondary",
                                                                                    },
                                                                                },
                                                                                "v-icon",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-account-supervisor "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Héritage par service(s) / fonction(s)"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]),
                                      ]
                                    },
                                  }
                                }),
                                {
                                  key: "top",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mx-auto mb-4",
                                          attrs: { flat: "", outlined: "" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "ml-4 mt-4 mb-4",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "Service(s) du collaborateur :"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "9" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.userServices,
                                                      function (service) {
                                                        return _c(
                                                          "div",
                                                          { key: service.name },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    service.name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "ml-4 mt-4 mb-4",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "Fonction(s) du collaborateur :"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "9" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.userFunctions,
                                                      function (func) {
                                                        return _c(
                                                          "div",
                                                          { key: func.name },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mx-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    func.name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "mx-2",
                                            attrs: {
                                              "on-icon": "mdi-checkbox-outline",
                                              "off-icon":
                                                "mdi-checkbox-blank-outline",
                                              label:
                                                "Afficher les rôles non associés",
                                            },
                                            model: {
                                              value: _vm.viewAllRole,
                                              callback: function ($$v) {
                                                _vm.viewAllRole = $$v
                                              },
                                              expression: "viewAllRole",
                                            },
                                          }),
                                          _c("v-text-field", {
                                            staticClass: "mx-4 ml-16",
                                            attrs: {
                                              label: "Rechercher un rôle",
                                            },
                                            model: {
                                              value: _vm.roleSearch,
                                              callback: function ($$v) {
                                                _vm.roleSearch = $$v
                                              },
                                              expression: "roleSearch",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.modeEdition
                    ? _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2 px-4 btn",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled: !_vm.hasChanged || _vm.loading,
                              },
                              on: { click: _vm.save },
                            },
                            [
                              _c("div", { staticClass: "capitalize" }, [
                                _vm._v("enregistrer"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AlertNotSavedModifsComponent", {
        attrs: { show: _vm.showAlertQuit },
        on: { quit: _vm.onQuitAlert, notquit: _vm.onNotQuitAlert },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }